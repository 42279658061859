import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import GridContainer from '../../helpers/GridContainer';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import imgTeam from '../../assets/images/team.png';
import imgTeamHappy from '../../assets/images/team-happy.png';
import checkImg from '../../assets/images/check.svg';

const ListItemCheck = [
  { label: 'Frota própria' },
  { label: 'Sistema de rastreamento veicular e gestão de frotas' },
  { label: 'Oficina própria dedicada na manutenção dos veículos' },
  {
    label:
      'Galpão bem localizado, estruturado e protegido 24 hrs por câmeras e sistema de vigilância',
  },
  { label: 'Equipe de manuseio e acompanhamento de entrega' },
];

const About: React.FC = () => {
  const history = useHistory();

  const switchToContactPage = () => {
    history.push('/contact');
  };

  return (
    <DefaultLayout>
      <GridContainer>
        <S.WrapperSection>
          <S.WrapperImg>
            <img src={imgTeam} alt="Imagem do time" />
          </S.WrapperImg>
          <S.Section>
            <div className="content">
              <h2>Sobre nós</h2>
              <p>
                Fundada em 2003 em Minas Gerais, a Via Lactea Express é uma
                empresa de logística comprometida em fornecer soluções
                personalizadas e flexíveis para nossos clientes.
              </p>
              <p>
                Nosso foco em tecnologia de gestão de transporte (TMS) nos
                permite combinar modalidades LTL e FTL para oferecer tarifas
                competitivas e reduzir significativamente os seus custos
                operacionais.
              </p>
              <p>
                Além disso, estamos preparados para enfrentar todos os desafios
                logísticos que possa ocorrer a nossos clientes, pois possuimos
                infraestrutura e tecnologia de ponta em nossos processos.
              </p>
              <p>
                Com expertise em setores como alimentício, têxtil, cosmético e
                beleza com um sistema de controle e rastreamento de entregas
                altamente eficiente.
              </p>
              <p>
                Isso significa que nossos clientes podem rastrear seus objetos
                desde o pedido da coleta até a entrega ao destinatário final,
                tendo total visibilidade e controle sobre suas operações
                logísticas. Como líderes em soluções logísticas, estamos sempre
                prontos para ajudar nossos clientes a alcançar seus objetivos de
                negócios com eficiência e confiabilidade.
              </p>
            </div>
          </S.Section>
        </S.WrapperSection>
        <S.WrapperSectionInverse>
          <S.Section>
            <div className="content">
              <h2>Equipe de confiança</h2>
              <p>
                Nossos colaboradores são altamente qualificados e 100%
                contratados e regulamentados, conforme determina as legislações
                de Trabalho (CLT) e trânsito (CONTRAN), proporcionando segurança
                jurídica na prestação dos serviços.
              </p>
              <p>
                Usamos o modelo flexível da Continental, com transportadores de
                qualidade. Dessa forma, você se beneficia de níveis de serviço
                aprimorados, personalizados e entregas definidas no tempo.
              </p>
              <p>
                Nossa experiência em gerenciamento e planejamento de transporte
                nos permite projetar uma solução que atenda às suas necessidades
                e também responda rapidamente a quaisquer desafios no processo.
              </p>
            </div>
          </S.Section>
          <S.WrapperImg>
            <img src={imgTeamHappy} alt="Imagem do time feliz" />
          </S.WrapperImg>
        </S.WrapperSectionInverse>
        <S.WrapperSection>
          <S.WrapperImg>
            <img src={imgTeamHappy} alt="Imagem do time feliz" />
          </S.WrapperImg>
          <S.Section>
            <div className="content">
              <h2>Nossa estrutura</h2>
              <p>
                Estamos localizado em Contagem, prontamente estruturados para
                atender toda região metropolitana de Belo Horizonte e o estado
                de Minas Gerais com flexibilidade, compromisso e qualidade.
              </p>
              <S.CheckListItem>
                {ListItemCheck.map(({ label }) => {
                  return (
                    <S.Item>
                      <img src={checkImg} alt="Check" />
                      <p>{label}</p>
                    </S.Item>
                  );
                })}
              </S.CheckListItem>
            </div>
          </S.Section>
        </S.WrapperSection>
      </GridContainer>
      <S.FooterCardSection id="contact">
        <GridContainer>
          <S.FooterCard>
            <p>
              Temos a solução ideal para sua empresa, agende uma conversa agora!
            </p>
            <Button onClick={switchToContactPage}>agendar uma conversa</Button>
          </S.FooterCard>
        </GridContainer>
      </S.FooterCardSection>
    </DefaultLayout>
  );
};

export default About;
