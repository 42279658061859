import airplaneImg from '../../assets/images/airplane.png';
import dockImg from '../../assets/images/dock.png';
import roadImg from '../../assets/images/road.png';

import renataImg from '../../assets/images/renata.png';
import leticiaImg from '../../assets/images/leticia.png';
import alineImg from '../../assets/images/aline.png';

// Icons
import desembaracoImg from '../../assets/images/desembaraco.svg';
import assessoriaImg from '../../assets/images/assessoria.svg';
import placeImg from '../../assets/images/place.svg';
// import treinamentoImg from 'assets/images/treinamento.svg';

type Logistic = {
  cover: string;
  title: string;
  items: string[];
};

type People = {
  avatar: string;
  name: string;
  position: string;
  company: string;
  report: string;
};

type Collapse = {
  icon: string;
  title: string;
  description: string;
  items: string[];
};

export const logistics: Logistic[] = [
  {
    cover: airplaneImg,
    title: 'services.content.air.title',
    items: [
      'services.content.air.one',
      'services.content.air.two',
      'services.content.air.three',
      'services.content.air.four',
      'services.content.air.five',
    ],
  },
  {
    cover: dockImg,
    title: 'services.content.ocean.title',
    items: [
      'services.content.ocean.one',
      'services.content.ocean.two',
      'services.content.ocean.three',
      'services.content.ocean.four',
      'services.content.ocean.five',
    ],
  },
  {
    cover: roadImg,
    title: 'services.content.truck.title',
    items: [
      'services.content.truck.one',
      'services.content.truck.two',
      'services.content.truck.three',
      'services.content.truck.four',
      'services.content.truck.five',
    ],
  },
];

export const peoplesReports: People[] = [
  {
    name: 'Renata Cardilo',
    position: 'Compradora Commodities Importação,',
    company: 'Benco Aço',
    report:
      '”Temos a Via lactea trasnportes como nosso parceiro na parte do desembaraço aduaneiro e assessoria à importação. Esse relacionamento vem de muitos anos, no qual sempre nos deu muita segurança no atendimento de nossas necessidades e credibilidade para tanto”',
    avatar: renataImg,
  },
  {
    name: 'Letícia R. Cardoso',
    position: 'Supply Analyst,',
    company: 'DigitalNETBR',
    report:
      '”Poder contar com o apoio da Via lactea trasnportes em nossos negócios representa tranquilidade, seriedade e clareza. Enfrentamos juntos muitos desafios e sempre alcançamos a solução de forma prática e objetiva. Sempre temos um suporte de excelência e inteligência em comércio exterior, com idoneidade.”',
    avatar: leticiaImg,
  },
  {
    name: 'Aline Cruz',
    position: 'Executiva Comex,',
    company: 'Alimentos Wilson',
    report:
      '”Com a Via lactea trasnportes e temos o suporte que precisamos para nossa gestão de exportação. Um time que possui grande conhecimento para nos ajudar, providenciando todas as informações para tomadas de decisões. Auxilia nossa equipe na coordenação de embarques, documentações e principalmente questões fiscais e aduaneiras.”',
    avatar: alineImg,
  },
];

export const logisticCollapse: Collapse[] = [
  {
    title: 'services.collapse.one.title',
    description: 'services.collapse.one.title',
    icon: desembaracoImg,
    items: [
      'services.collapse.one.items.one',
      'services.collapse.one.items.two',
      'services.collapse.one.items.three',
      'services.collapse.one.items.four',
      'services.collapse.one.items.five',
    ],
  },
  {
    title: 'services.collapse.two.title',
    description: 'services.collapse.two.description',
    icon: assessoriaImg,
    items: [
      'services.collapse.two.items.one',
      'services.collapse.two.items.two',
      'services.collapse.two.items.three',
      'services.collapse.two.items.four',
    ],
  },
  {
    title: 'services.collapse.three.title',
    description: 'services.collapse.three.description',
    icon: placeImg,
    items: [
      'services.collapse.three.items.one',
      'services.collapse.three.items.two',
      'services.collapse.three.items.three',
      'services.collapse.three.items.four',
    ],
  },
];
