import styled from 'styled-components';

import compassImg from '../../assets/images/compass.png';

export const Container = styled.footer`
  background: url(${compassImg}) no-repeat right bottom,
    ${({ theme }) => theme.colors.primary['blue-500']};
  padding: 110px 0 45px;

  .wrapper {
    display: flex;
    justify-content: space-between;

    nav {
      display: flex;
      flex-direction: column;

      > a {
        font-family: PTSansRegular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: ${({ theme }) => theme.colors.background};

        text-decoration: none;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    .nav-desk {
    }

    .nav-mobile {
      display: none;
    }

    .contact-area {
      display: flex;
      flex-direction: column;
      margin-right: 85px;

      .item {
        display: flex;
        align-items: center;
        text-decoration: none;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        p {
          margin-left: 8px;
          text-decoration: none;

          font-family: OpenSansRegular;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 150%;
          color: ${({ theme }) => theme.colors.background};
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    > img {
      height: 40px;
      width: 160px;
    }

    .info {
      display: flex;
      flex-direction: column;
      margin-top: 25px;

      > .location {
        font-family: RalewayBold;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.background};
      }
      > .address {
        margin-top: 8px;
        max-width: 307px;

        font-family: OpenSansRegular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.background};
      }
    }

    .social-icons {
      margin-top: 32px;

      display: flex;
      align-items: center;

      a + a {
        margin-left: 15px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 50px 16px 40px;
    padding-top: 50px;

    .wrapper {
      flex-direction: column;
      align-items: center;

      .nav-desk {
        display: none;
      }

      .nav-mobile {
        margin-top: 24px;

        display: flex;
        align-items: center;
      }

      .contact-area {
        text-align: center;
        align-items: center;
        margin-top: 24px;
        margin-right: 0;

        .item p {
          text-align: center;
        }
      }
    }

    .content {
      align-items: center;
    }

    .info {
      align-items: center;
      text-align: center;
    }
  }
`;

export const DividerContainer = styled.div`
  margin-top: 25px;

  .divisor {
    border: 0.5px solid ${({ theme }) => theme.colors.background};
  }

  .company {
    margin-top: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > p {
      font-family: OpenSansRegular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.background};

      > a {
        font-family: OpenSansBold;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.background};
        text-decoration: none;
      }
    }
  }

  @media (max-width: 768px) {
    .divisor {
      display: none;
    }

    .company {
      flex-direction: column;
      align-items: center;
      text-align: center;

      p + p {
        margin-top: 32px;
      }
    }
  }
`;

export const MobileContainer = styled.div`
  display: none;
`;
