import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import 'swiper/swiper-bundle.min.css';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DefaultLayout from '../../layouts/DefaultLayout';
import GridContainer from '../../helpers/GridContainer';
import Button from '../../components/Button';

import checkImg from '../../assets/images/check.svg';
import vilmaIcon from '../../assets/images/vilmalogo.png';
import labelRougeIcon from '../../assets/images/labelRougeIcon.png';
import albanos from '../../assets/images/albanos.png';
import laut from '../../assets/images/laut.png';
import tempoIcon from '../../assets/images/jornaltempoIcon.png';

import {
  Container,
  TitleSection,
  TitleContainer,
  WrapperTitle,
  Title,
  FooterCardSection,
  FooterCard,
  WrapperClients,
  WrapperLogos,
  CollapseLogisticSection,
  CollapseCard,
  CollapseContentContainer,
  CollapseLogisticSectionMobile,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from './styles';

import { logisticCollapse } from './data';

const listLogoClients = [
  { logo: vilmaIcon, alt: 'Vilma', url: 'https://www.vilma.com.br' },
  {
    logo: labelRougeIcon,
    alt: 'Label rouge',
    url: 'https://labelrouge.com.br',
  },
  { logo: albanos, alt: 'Cervejaria albanos', url: 'https://albanos.com.br' },
  {
    logo: laut,
    alt: 'Cervejaria laut',
    url: 'https://www.cervejarialaut.com.br',
  },
  { logo: tempoIcon, alt: 'Jornal O tempo', url: 'https://www.otempo.com.br/' },
];

const Works: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [logisticCollapseValue, setLogisticCollapseValue] = useState(0);
  const [expandedCollapse, setExpandedCollapse] = useState(-1);

  const isCollapseActive = (logisticValue: number): boolean =>
    logisticCollapseValue === logisticValue;

  const handleActiveCollapse = (logisticValue: number) => {
    setLogisticCollapseValue(logisticValue);
  };

  const handleMobileCollapse = (collapse: number): void => {
    setExpandedCollapse(lastValue => (lastValue === collapse ? -1 : collapse));
  };

  const switchToContactPage = () => {
    history.push('/contact');
  };

  return (
    <DefaultLayout>
      <Container>
        <TitleSection>
          <GridContainer>
            <TitleContainer>
              <div className="content">
                <h1>A melhor solução logística para sua empresa!</h1>
              </div>
            </TitleContainer>
          </GridContainer>
        </TitleSection>

        {/* Detalhes sobre o serviço prestado */}
        <CollapseLogisticSection>
          <GridContainer>
            <div className="wrapper">
              <div className="collapse-container">
                {logisticCollapse.map((item, index) => (
                  <CollapseCard
                    active={isCollapseActive(index)}
                    onClick={() => handleActiveCollapse(index)}
                  >
                    <div className="title-container">
                      <img src={item.icon} alt="Icon" className="icon" />
                      <div className="container">
                        <p className="title">{t(item.title)}</p>
                        <p className="description">{t(item.description)}</p>
                      </div>
                    </div>
                  </CollapseCard>
                ))}
              </div>
              <CollapseContentContainer>
                <WrapperTitle>
                  <Title>Nossos Serviços</Title>
                </WrapperTitle>
                <p className="title">
                  {t(logisticCollapse[logisticCollapseValue].title)}
                </p>
                <p className="about">{t('common.provideyou')}</p>
                <div className="item-container">
                  {logisticCollapse[logisticCollapseValue].items.map(item => (
                    <div className="item">
                      <img src={checkImg} alt="Check" />
                      <p>{t(item)}</p>
                    </div>
                  ))}
                </div>
              </CollapseContentContainer>
            </div>
          </GridContainer>
        </CollapseLogisticSection>

        {/* Logistic Explanation Mobile */}
        <CollapseLogisticSectionMobile>
          <GridContainer>
            {logisticCollapse.map((item, index) => (
              <Accordion
                expanded={expandedCollapse === index}
                onChange={() => handleMobileCollapse(index)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="title-container">
                    <img src={item.icon} alt="Icon" className="icon" />
                    <p>{t(item.title)}</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="description">{t(item.description)}</p>
                  <CollapseContentContainer>
                    <p className="about">{t('common.provideyou')}</p>
                    <div className="item-container">
                      {item.items.map(content => (
                        <div className="item">
                          <img src={checkImg} alt="Check" />
                          <p>{t(content)}</p>
                        </div>
                      ))}
                    </div>
                  </CollapseContentContainer>
                </AccordionDetails>
              </Accordion>
            ))}
          </GridContainer>
        </CollapseLogisticSectionMobile>

        <GridContainer>
          <WrapperClients>
            <Title>
              Conheça nossos
              <br />
              clientes...
            </Title>
            <WrapperLogos>
              {listLogoClients.map(logo => {
                return (
                  <>
                    <a href={logo.url} rel="noreferrer" target="_blank">
                      <img src={logo.logo} alt={logo.alt} />
                    </a>
                  </>
                );
              })}
            </WrapperLogos>
          </WrapperClients>
        </GridContainer>
        {/* rodapé */}
        <FooterCardSection id="contact">
          <GridContainer>
            <FooterCard>
              <p>Descubra como melhorar a eficiência das suas logísticas</p>
              <Button onClick={switchToContactPage}>
                agendar uma conversa
              </Button>
            </FooterCard>
          </GridContainer>
        </FooterCardSection>
      </Container>
    </DefaultLayout>
  );
};

export default Works;
