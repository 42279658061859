export const messages = {
  pt: {
    translations: {
      contactPage: {
        validationFields: {
          requiredName: 'Por favor, preencha seu nome.',
          requiredEmail: 'Por favor, preencha um e-mail.',
          requiredPhone: 'Por favor, preencha seu telefone.',
          validEmail: 'Por favor, preencha um e-mail válido.',
        },
        title: 'Vamos conversar?',
        form: {
          title: 'Estamos te esperando',
          subtitle: 'Ficaremos felizes com seu contato.',
          name: 'Seu nome*',
          email: 'Seu melhor e-mail*',
          phone: 'Seu melhor telefone*',
          message: 'Mensagem',
          button: 'Enviar Contato',
        },
        contactUs: {
          title: 'Nossos contatos',
          services: {
            servicesTitle: 'Serviços',
            one: 'Agenciamento de cargas',
            two: 'Desembaraço aduaneiro',
            three: 'Assessoria aduaneira e projetos',
            four: 'Assessoria na Impo. e Expo. de serviços',
            five: 'Treinamentos personalizados',
            six: 'Parcerias no exterior',
          },
        },
      },
      header: {
        aboutus: 'Sobre nós',
        services: 'Serviços',
        solutions: 'Soluções',
        contactus: 'Contato',
      },
      main: {
        title: 'Excelência, pontualidade e segurança',
        subtitle:
          'Entregamos a melhor solução em entregas fracionadas, com compromisso, qualidade e agilidade.',
      },
      about: {
        title:
          'Somos uma empresa especializada em gestão de transportes, entregas de encomendas expressas e soluções logísticas',
        subtitle:
          'Fundada em Minas Gerais em 2013 e consolidada desde então, oferecemos soluções logísticas completas no Estado para diversos segmentos, incluindo alimentício, têxtil, cosmético e beleza. Com processos definidos e controles personalizados para cada demanda, permitimos que nossos clientes se concentrem em suas atividades estratégicas e comerciais, aumentando o potencial de crescimento e sucesso de seus negócios.Com nossa equipe altamente capacitada, tecnologia de ponta, infraestrutura e experiência no ramo, garantimos a excelência em nossos serviços e a satisfação total dos nossos clientes.',
      },
      services: {
        title: 'Solução em transportes expressos',
        subtitle:
          'Gestão do transporte e soluções para os desafios logísticos para a sua empresa',
        tabs: {
          air: 'Aéreo',
          ocean: 'Marítimo',
          truck: 'Rodoviário',
        },
        content: {
          air: {
            title: 'Sua carga voando de forma mais segura.',
            one:
              'Coletamos sua carga em qualquer ponto do Brasil e em mais de 120 países no exterior.',
            two: 'Possuímos tarifas competitivas nas mais diferentes rotas.',
            three:
              'Somos parceiros de agentes internacionais ligados a World Cargo Alliance (WCA).',
            four:
              'Atuamos com o transporte de cargas normais, IMO ou perecíveis.',
            five:
              'Atuamos com envio de cargas para Feiras e Eventos Internacionais.',
          },
          ocean: {
            title: 'Atuação em FCL, LCL e RO-RO.',
            one:
              'Coletamos sua carga em qualquer ponto do Brasil e em mais de 120 países no exterior em full Container ou LCL.',
            two:
              'Somos parceiros dos maiores consolidadores de cargas do Brasil;',
            three:
              'Nossa parceria com os agentes da World Cargo Alliance (WCA) nos dá maior flexibilidade no atendimento de diferentes rotas.',
            four:
              'As cargas com dimensões especiais ou roll-on roll-off também são atendidas de forma profissional pelo nosso time.',
            five:
              'Atuamos com o transporte de cargas normais, IMO ou perecíveis, incluindo carga congelada ou refrigerada.',
          },
          truck: {
            title:
              'As exportações e importações rodoviárias também podem ser atendidas por este importante modal.',
            one: 'Possuímos tarifas competitivas nas mais diferentes rotas.',
            two: 'Rastreamento de cargas e encomendas',
            three:
              'Aplicativo rápido e eficiente para rastreamento, contato com fornecedores e criação de redes personalizadas',
            four:
              'Manejamos produtos de todas as indústrias de acordo com suas características e especificidade, como dimensão, tamanho e peso.',
            five:
              'Nós usamos tecnologia de gerenciamento de transporte (TMS) para combinar as modalidades LTL e FTL, o que reduz os seus custos operacionais de forma significativa.',
            six: 'Nível de serviço aprimorado e personalizado à sua demanda',
            seven: 'Infraestrutura segura e eficiente com tecnologias de ponta',
          },
        },
        collapse: {
          one: {
            title: 'Entrega expressa',
            description: 'Entregas rápidas de verdade',
            items: {
              one: 'Porta a porta',
              two: 'Personalização do serviço disponivel',
              three: 'Entregas no próximo dia útil',
              four: 'Entrega segura com rastreamento em tempo real',
              five: 'Atendimento personalizado ao cliente final',
            },
          },
          two: {
            title: 'Amazenagem',
            description:
              'Conheça como te ajudamos a crescer e atender melhor a seus clientes',
            items: {
              one:
                'Recebemos e verificamos suas mercadorias para garantir que estejam em conformidade com seus padrões de qualidade.',
              two:
                'Armazenamos de forma segura e organizada para facilitar a movimentação e controle de estoque',
              three:
                'Seu estoque é gerenciado com tecnologia de ponta, isso permite que você tenha controle da localização e quantidade dos seus produtos',
              four:
                'Você vende, nós cuidamos do restante (embalagem, documentação e entrega)',
            },
          },
          three: {
            title: 'Cargas fracionadas',
            description:
              'A melhor opção para quem precisa enviar pequenos volumes para destinos variados',
            items: {
              one: 'Fazemos coleta e entrega de mercadorias',
              two:
                'Consolidação e roteirização de entregas para garantir maior eficiência e agilidade',
              three:
                'Agendamento de coletas e entregas, trazendo flexibilidade no atendimento ao cliente.',
              four: 'Embalagem e manuseio de produtos',
            },
          },
        },
      },
      common: {
        provideyou: 'O que fazemos:',
      },
      solutions: {
        title: 'Conheça nossas soluções em logística',
        description:
          'Crie um diferencial no seu negócio com entregas pontuais, rastreáveis e uma logística eficiente em Minas Gerais.',
      },
      contact: {
        title: 'Potencialize seu negócio hoje mesmo',
        buttonText: 'Agendar uma conversa',
      },
      footer: {
        aboutus: 'Sobre nós',
        services: 'Serviços',
        solutions: 'Soluções',
        contactus: 'Contato',
      },
    },
  },
};
