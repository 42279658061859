import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { Container } from './styles';

type Props = {
  children: React.ReactNode;
  variant?: 'light';
};

const DefaultLayout: React.FC<Props> = ({
  children,
  variant = 'light',
}: Props) => {
  return (
    <Container>
      <Header variant={variant} />
      <main>{children}</main>
      <Footer />
    </Container>
  );
};

DefaultLayout.defaultProps = {
  variant: 'light',
};

export default DefaultLayout;
