import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WrapperSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 57px;
  justify-content: space-between;

  @media (max-width: 1110px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const WrapperSectionInverse = styled.div`
  display: flex;
  flex-direction: row;
  padding: 57px;
  justify-content: space-between;

  @media (max-width: 1110px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WrapperImg = styled.div`
  max-width: 470px;
  max-height: 465px;

  @media (max-width: 1110px) {
    margin-bottom: 30px;

  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  width: 100%;
  max-width: 435px;

  .content {

    > h2 {
      font-family: RalewayBold;
      font-style: normal;
      font-weight: bold;
      text-transform: capitalize;
      font-size: 32px;
      line-height: 130%;
      color: ${({ theme }) => theme.colors.primary};
    }

    > p {
      margin-top: 16px;

      font-family: PTSansRegular;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      text-align: justify;
      white-space: pre-line;
      line-height: 130%;
      color: ${({ theme }) => theme.colors.primary};
    }

    @media (max-width: 1110px) {
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    margin: 0 auto;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > h1 {
        margin-top: 25px;
        font-size: 32px;
        text-align: center;
      }
      > h4 {
        text-align: center;
      }
    }
  }
`;

export const CheckListItem = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;

  gap: 16px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-top: 16px;
  }
`;

export const FooterCardSection = styled.section`
  margin-top: 60px;

  width: 100%;
  background: linear-gradient(
    to top,
    ${({ theme }) => theme.colors.primary['blue-500']} 50%,
    ${({ theme }) => theme.colors.background} 50%
  );
`;

export const FooterCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 28px 28px 28px 28px;
  gap: 28px;

  background: #f1f3f5;
  box-shadow: 30px 0px 48px -8px rgba(102, 126, 154, 0.25);
  border-radius: 5px;

  > p {
    max-width: auto;
    text-align: center;
    font-family: RalewayBold;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.primary['blue-500']};
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px 5px;

    > p {
      font-size: 24px;
      text-align: center;
    }

    > button {
      width: 215px;
      margin-top: 24px;
    }
  }
`;
