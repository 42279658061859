import React from 'react';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Home from '../pages/Home';
import About from '../pages/About';
import Works from '../pages/Works';
import Contact from '../pages/Contact';

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/" exact>
          <Redirect to="/home" />
        </Route>
        <Route path="/servicos" component={Works} />
        <Route path="/contact" component={Contact} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
