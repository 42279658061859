import styled, { css } from 'styled-components';

import {
  Tabs as MuiTabs,
  Tab as MuiTab,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core';
import { motion } from 'framer-motion';

import dockImg from '../../assets/images/hero.png';
import dockImgMobile from '../../assets/images/image-hero-mobile.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 533px;

  background: url(${dockImg}) no-repeat,
    linear-gradient(90deg, #002856 9.03%, rgba(204, 212, 221, 0.05) 129.52%);
  background-size: contain;
  background-position: center top;
  background-attachment: fixed;

  @media (max-width: 1655px) {
    background-size: cover;
    background-position: center top;
    background-attachment: initial;
  }

  @media (max-width: 768px) {
    height: 285px;
    background: url(${dockImgMobile}) no-repeat,
      linear-gradient(90deg, #002856 9.03%, rgba(204, 212, 221, 0.05) 129.52%);

    &:before {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  width: 100%;
  max-width: 435px;

  .content {
    margin-bottom: 30px;

    > h1 {
      font-family: RalewayBold;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 130%;
      color: ${({ theme }) => theme.colors.background};
    }

    > h4 {
      margin-top: 16px;

      font-family: PTSansRegular;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.background};
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    margin: 0 auto;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > h1 {
        margin-top: 25px;
        font-size: 32px;
        text-align: center;
      }
      > h4 {
        text-align: center;
      }
    }
  }
`;

export const AboutContainer = styled.div`
  padding: 60px 0;
  margin-top: -30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #f8f9fa;
  box-shadow: 0px 30px 48px -8px rgba(102, 126, 154, 0.25);
  border-radius: 5px;

  .content {
    max-width: 750px;

    > h2 {
      font-family: RalewayBold;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 150%;
      text-align: center;
      color: #002856;
    }

    > p {
      margin-top: 16px;

      font-family: PTSansRegular;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      color: ${({ theme }) => theme.colors.text['gray-8']};

      > span {
        font-family: PTSansBold;
        font-weight: bold;
      }
    }
  }

  @media (max-width: 768px) {
    margin-right: 16px;
    margin-left: 16px;
    padding: 25px 0;

    .content {
      margin: 0 30px;

      > h2 {
        margin: 0 15px;
        font-size: 18px;
      }

      > p {
        font-size: 16px;
      }
    }
  }
`;

export const LogisticServiceSection = styled.div`
  margin-top: 100px;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 40px;
    /* padding: 0 16px; */
  }
`;

export const LogisticContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;

  > .content {
    > h1 {
      font-family: RalewayBold;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 130%;
      color: ${({ theme }) => theme.colors.primary['blue-500']};
    }

    > p {
      margin-top: 15px;
      max-width: 68%;

      font-family: OpenSansRegular;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.text['gray-8']};
    }
  }

  @media (max-width: 1280px) {
    display: inline-block;

    > img {
      display: none;
    }

    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 768px) {
    display: inline-block;
    padding: 0 16px;

    > .content {
      > h1 {
        max-width: 330px;
        font-size: 24px;
        text-align: center;
      }

      > p {
        max-width: 330px;
        font-size: 14px;
        text-align: center;
      }
    }
  }
`;

export const LogisticTabsContainer = styled.div`
  margin-top: 32px;

  display: flex;
  flex-direction: column;
`;

export const Tabs = styled(MuiTabs)`
  .MuiTabs-indicator {
    background: ${({ theme }) => theme.colors.secondary['coral-500']};
    border: 1px solid ${({ theme }) => theme.colors.secondary['coral-500']};
  }
`;

export const Tab = styled(MuiTab)`
  .MuiTab-wrapper {
    font-family: RalewayRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: ${({ theme }) => theme.colors.text['gray-5']};

    text-transform: initial;
  }

  &.Mui-selected .MuiTab-wrapper {
    font-family: RalewayBold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
    color: ${({ theme }) => theme.colors.secondary['coral-500']};
    text-transform: initial;
  }

  @media (max-width: 768px) {
    .MuiTab-wrapper {
      font-size: 16px;
    }

    &.Mui-selected .MuiTab-wrapper {
      font-size: 16px;
    }
  }
`;

export const TabContentContainer = styled(motion.div)`
  margin-top: 16px;

  .title {
    /* max-width: 380px; */

    font-family: RalewayBold;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.primary['blue-500']};
  }

  .minor {
    font-size: 24px;
  }

  .container {
    margin-top: 25px;
    display: grid;
    grid-template-columns: 287px 287px;
    grid-column-gap: 40px;
    grid-row-gap: 16px;

    .item {
      display: flex;
      align-items: flex-start;

      > img {
        margin-top: 3px;
        margin-right: 10px;
      }

      > p {
        max-width: 255px;

        font-family: OpenSansRegular;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.text['gray-8']};
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0 16px;

    .title {
      font-size: 20px;
      text-align: center;
    }

    .minor {
      font-size: 17px;
    }

    .container {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const FooterCardSection = styled.section`
  margin-top: 60px;

  width: 100%;
  background: linear-gradient(
    to top,
    ${({ theme }) => theme.colors.primary['blue-500']} 50%,
    ${({ theme }) => theme.colors.background} 50%
  );
`;

export const FooterCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 85px 85px 85px 100px;

  background: #f1f3f5;
  box-shadow: 30px 0px 48px -8px rgba(102, 126, 154, 0.25);
  border-radius: 5px;

  > p {
    max-width: 410px;

    font-family: RalewayBold;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.primary['blue-500']};
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px 5px;

    > p {
      font-size: 24px;
      text-align: center;
    }

    > button {
      width: 215px;
      margin-top: 24px;
    }
  }
`;

export const PeopleReportsContainer = styled.div`
  margin-top: 80px;

  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .title {
    max-width: 390px;

    font-family: RalewayBold;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 130%;
    color: ${({ theme }) => theme.colors.primary['blue-500']};
  }

  .content {
    margin-top: 40px;

    display: flex;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const PeopleReportsMobileContainer = styled.div`
  display: none;
  margin-top: 40px;
  padding-left: 16px;

  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .title {
    text-align: center;
    font-family: RalewayBold;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary['blue-500']};
  }

  .content-container {
    margin-top: 24px;
  }

  /* .swiper-slide-active {
    display: flex;
    justify-content: center;
  } */

  .swiper-slide {
    width: auto;
  }

  @media (max-width: 768px) {
    display: inline;
  }
`;

export const PeopleCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 30px 25px 30px 30px;

  height: 360px;
  width: 100%;
  max-width: 310px;

  background: #f1f3f5;
  border-radius: 5px;

  &:not(:last-child) {
    margin-right: 30px;
  }

  .report {
    max-width: 280px;

    font-family: OpenSansRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    color: ${({ theme }) => theme.colors.text['gray-7']};
  }

  .info {
    display: flex;

    > div {
      :last-child {
        margin-left: 10px;

        display: flex;
        flex-direction: column;

        .name {
          font-family: OpenSansBold;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 130%;
          color: ${({ theme }) => theme.colors.text['gray-7']};
        }
        .position {
          font-family: OpenSansRegular;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 130%;
          color: ${({ theme }) => theme.colors.text['gray-7']};
        }
        .company {
          font-family: OpenSansRegular;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 130%;
          color: ${({ theme }) => theme.colors.text['gray-7']};
        }
      }
    }
  }
`;

export const SolutionsSection = styled.section`
  margin-top: 150px;
  width: 100%;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    margin-top: 40px;

    .content {
      > img {
        display: none;
      }
    }

    > div {
      width: 100%;
      max-width: 100vw;
    }
  }
`;

export const SolutionsCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 120px 42px;
  background: ${({ theme }) => theme.colors.primary['blue-500']};
  border-radius: 5px;

  margin-right: -100px;
  z-index: 1000;

  .title {
    font-family: RalewayBold;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 130%;
    color: ${({ theme }) => theme.colors.background};

    max-width: 390px;
  }

  .description {
    margin-top: 16px;

    font-family: OpenSansRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.background};

    max-width: 320px;
  }

  @media (max-width: 768px) {
    width: 100vw;
    margin-right: 0;
    padding: 40px 15px;
    border-radius: 0;
    align-items: center;

    > .title {
      text-align: center;
      font-size: 32px;
    }

    > .description {
      margin-top: 24px;
      padding: 0 25px;
      font-size: 16px;
      text-align: center;
    }
  }
`;

export const CollapseLogisticSection = styled.section`
  margin-top: 80px;

  .wrapper {
    display: flex;
    align-items: flex-start;
  }

  .collapse-container {
    display: flex;
    flex-direction: column;

    width: 50%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const CollapseCard = styled.div<{ active: boolean }>`
  cursor: pointer;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0px 0px 20px rgba(102, 126, 154, 0.25);
  border-radius: 5px;
  padding: 32px 24px;
  width: 100%;

  transition: all 0.2s linear;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .container {
      display: flex;
      flex-direction: column;
    }

    .title {
      margin-left: 16px;

      font-family: RalewayBold;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 130%;
      color: ${({ theme }) => theme.colors.secondary['coral-500']};
    }
    .description {
      display: none;
      margin-left: 16px;
      margin-top: 8px;
      /* display: none; */

      max-width: 280px;

      font-family: OpenSansRegular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 130%;
      color: ${({ theme }) => theme.colors.text['gray-7']};
    }
  }

  ${({ active }) =>
    active &&
    css`
      border-left: 8px solid
        ${({ theme }) => theme.colors.secondary['coral-500']};

      .title-container .description {
        display: initial;
      }
    `}
`;

export const CollapseContentContainer = styled.div`
  width: 50%;
  margin-left: 30px;
  display: flex;
  flex-direction: column;

  .title {
    font-family: RalewayBold;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 130%;
    color: ${({ theme }) => theme.colors.secondary['coral-500']};
  }

  .about {
    margin-top: 16px;

    font-family: RalewayBold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 130%;
    color: ${({ theme }) => theme.colors.text['gray-7']};
  }

  .item-container {
    margin-top: 16px;

    display: flex;
    flex-direction: column;

    > .item {
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      > img {
        margin-top: 3px;
      }

      > p {
        margin-left: 8px;

        font-family: OpenSansRegular;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.text['gray-7']};
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-top: 16px;
  }
`;

export const CollapseLogisticSectionMobile = styled.div`
  margin-top: 25px;

  display: none;
  flex-direction: column;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const Accordion = styled(MuiAccordion)`
  && {
    background: ${({ theme }) => theme.colors.background};
    box-shadow: 0px 0px 20px rgba(102, 126, 154, 0.25);
    border-radius: 5px;

    .title-container {
      display: flex;
      align-items: center;

      > p {
        margin-left: 8px;

        font-family: RalewayBold;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 130%;
        color: ${({ theme }) => theme.colors.secondary['coral-500']};
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  ::before {
    display: none;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)``;

export const AccordionDetails = styled(MuiAccordionDetails)`
  && {
    display: flex;
    flex-direction: column;

    .description {
      font-family: OpenSansRegular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      color: ${({ theme }) => theme.colors.text['gray-7']};
    }
  }
`;
