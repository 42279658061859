import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1110px;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @media (max-width: 1110px) {
    max-width: 768px;
  }

  @media (max-width: 768px) {
    max-width: 540px;
  }

  @media (max-width: 540px) {
    max-width: 525px;
  }

  @media (max-width: 375px) {
    max-width: 360px;
  }

  @media (max-width: 330px) {
    max-width: 310px;
  }
`;
