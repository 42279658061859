import React from 'react';

import { useTranslation } from 'react-i18next';

import GridContainer from '../../helpers/GridContainer';

import whatsappImg from '../../assets/images/whatsapp.svg';
import phoneImg from '../../assets/images/phone.svg';
import mailImg from '../../assets/images/mail.svg';
import logoLight from '../../assets/images/logo-light.svg';

import { Container, DividerContainer } from './styles';

const linkWhatsappCreate = 'https://api.whatsapp.com/send?phone=5531991077581&text=Ol%C3%A1,%20vi%20seu%20trabalho%20e%20gostaria%20de%20solicitar%20um%20or%C3%A7amento'

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <GridContainer>
        <div className="wrapper">
          <div className="content">
            <img src={logoLight} alt="Via láctea transportes" />
            <nav className="nav-mobile">
              <a href="/home">Inicio</a>
              <a href="/about">{t('footer.aboutus')}</a>
              <a href="/servicos">{t('footer.services')}</a>
              <a href="/contact">{t('footer.contactus')}</a>
            </nav>
            <div className="info">
              <p className="location">Contagem-MG</p>
              <p className="address">
                Rua. Araraquara 50, Vila Paris, Contagem / MG, CEP
                32.372-020.
              </p>
            </div>
          </div>
          <nav className="nav-desk">
            <a href="/home">Inicio</a>
            <a href="/about">{t('footer.aboutus')}</a>
            <a href="/servicos">{t('footer.services')}</a>
            <a href="/contact">{t('footer.contactus')}</a>
          </nav>
          <div className="contact-area">
            <a href="https://api.whatsapp.com/send?phone=5531995883056" target="_blank" rel="noreferrer" className="item">
              <img src={whatsappImg} alt="WhatsApp" />
              <p>+55 31 98730-1137</p>
            </a>
            <a href="tel:553135934777" className="item">
              <img src={phoneImg} alt="Phone" />
              <p>+55 31 3593-4777</p>
            </a>
            <a href="emailto:joao@vialactea.com.br" className="item">
              <img src={mailImg} alt="Mail" />
              <p>comercial@lactlog.com.br</p>
            </a>
          </div>
        </div>
        <DividerContainer>
          <div className="divisor" />
          <div className="company">
            <p>
              Via Láctea - Transporte de cargas expressas, CNPJ:
              17.559.433/0001-84, todos os direitos reservados
              {' '}
            </p>
            <p>
              Feito por:
              <a href={linkWhatsappCreate} rel="noreferrer" target="_blank">
                &nbsp;
                Michael Mascarenhas
              </a>
            </p>
          </div>
        </DividerContainer>
      </GridContainer>
    </Container>
  );
};

export default Footer;
