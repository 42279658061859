export const messages = {
  es: {
    translations: {
      contactPage: {
        validationFields: {
          requiredName: 'Por favor, ponga su nombre.',
          requiredEmail: 'Por favor, complete un correo electrónico.',
          requiredPhone: 'Por favor complete su teléfono.',
          validEmail: 'Por favor, complete un correo electrónico válido.',
        },
        title: '¿Estas listo?',
        form: {
          title: 'Estamos le esperando',
          subtitle: 'Nos quedaremos contentos con su contacto',
          name: 'Nombre',
          email: 'E-mail',
          phone: 'Teléfono',
          message: 'Mensaje',
          button: 'Enviar',
        },
        contactUs: {
          title: 'Nuestros contactos',
          services: {
            servicesTitle: 'Servicios',
            one: 'Fletes internacionales',
            two: 'Despacho de aduana',
            three: 'Asesoría en aduana y proyectos',
            four: 'Asesoría en importación y exportación de servicios',
            five: 'Capacitación de personal',
            six: 'Agentes internacionales',
          },
        },
      },
      header: {
        aboutus: '¿Quiénes somos?',
        services: 'Servicios',
        solutions: 'Soluciones',
        contactus: 'Contacto',
      },
      main: {
        title:
          'Garantía y seguridad en importación y exportación es con Via lactea trasnportes',
        subtitle:
          'Ofrecemos las mejores soluciones en logistica internacional con inteligencia y eficiencia',
      },
      about: {
        title:
          'Uma empresa brasileira que conquistou Minas Gerais',
        subtitle:
          'Gestão do transporte e soluções para os desafios logísticos para a sua empresa',
      },
      services: {
        title: 'Agenciamento de Cargas',
        subtitle:
          'Hacemos el retiro y entregamos los productos de exportación y importación a través del transporte aéreo, marítimo y terrestre.',
        tabs: {
          air: 'Aéreo',
          ocean: 'Marítimo',
          truck: 'Terrestre',
        },
        content: {
          air: {
            title: 'Su carga volando con seguridad.',
            one:
              'Retiramos su carga en cualquier punto de Brasil y también en más de 120 países en el exterior.',
            two: 'Ofrecemos tarifas competitivas para las distintas rutas.',
            three:
              'Tenemos agentes de WCA - World Cargo Alliance que trabajan con nosotros.',
            four: 'Manejamos carga normal, peligrosa y perecedera.',
            five:
              'Despachamos mercaderías para ferias y eventos internacionales.',
          },
          ocean: {
            title: 'Solución en FCL, LCL y RO-RO',
            one:
              'Retiramos su carga en cualquier punto de Brasil y también en más de 120 países en el exterior en full container o LCL.',
            two:
              'Somos parceiros dos maiores consolidadores de cargas do Brasil;',
            three:
              'Con un trabajo en conjunto con nuestro agentes de WCO, nosotros tenemos condiciones de brindar un servicio con flexibilidad en distintas rutas.',
            four:
              'Somos expertos en brindar con seguridad transporte de cargas con exceso de dimensiones.',
            five:
              'Manejamos carga normal, peligrosa y perecedera, incluyendo productos refrigerados o congelados.',
          },
          truck: {
            title: '',
            one:
              'Tenemos opciones de transporte terrestre en exportación y importación desde y hacia Brasil, Argentina, Uruguay, Paraguay, Bolivia y Chile.',
            two:
              'Hacemos verificaciones bajo a los reglamentos internos de los paises de origen y destino de las mercaderías.',
            three:
              'Ofrecemos soluciones en transporte de mercadería normal y especiales, incluyendo las cargas refrigeradas y congeladas.',
            four:
              'Soportamos nuestros clientes en el arreglo de licencias especiales de tránsito.',
            five:
              'Clientes que necesitan hacer transporte internacional con camión propio puede recibir asesoría de nuestra parte.',
          },
        },
        collapse: {
          one: {
            title: 'Despacho de adunada',
            description:
              'Sus operaciones estan siempre seguras con Via lactea trasnportes.',
            items: {
              one: 'Análisis en aranceles.',
              two:
                'Hacemos análisis y proveemos factura comercial y packing list.',
              three:
                'Suministramos declaración de importación y exportación para la aduana de Brasil.',
              four:
                'Suministramos declaración de importación y exportación para la aduana de Brasil.',
              five:
                'Ofrecemos servicio de registro de empresas bajo a los requerimientos del Ministerio de Agricultura de Brasil.',
            },
          },
          two: {
            title: 'Asesoría en aduana y proyectos',
            description:
              'Conozca más sobre nuestra diversidad de actuación en asesoría a clientes. ',
            items: {
              one:
                'Reducción del impuesto de importación en Brasil por medio de ex-tarifario.',
              two:
                'Asesoria en impuestos de importación y exportación de productos y servicios.',
              three:
                'Brindamos escenarios de importación y exportación haciendo comparativos de distintas origenes, costes y impuestos. ',
              four:
                'Apoyamos en la estructuración de su departamento de exportación e importación.',
              five:
                'Asesoria completa para obtención de registro de productos en el Ministerio de Agricultura de Brasil.',
            },
          },
          three: {
            title: 'Asesoría en importación y exportación de servicios',
            description:
              'Conozca más sobre nuestra diversidad de actuación en asesoría a clientes. ',
            items: {
              one: 'Determinación de operaciones de servicios.',
              two: 'Analisis y determinación de los impuestos.',
              three: 'Ventajas de los acuerdos de doble tributación.',
              four: 'Tributación en operaciones con paraísos fiscales.',
              five:
                'Instrucciones sobre los trámites y derechos sobre servícios.',
              six: 'Emisión de guía de derechos tributarios.',
              seven:
                'Soporte en giros al exterior para cancelar pagos de importaciones o exportaciones.',
              eight:
                'Comisión sobre exportación e importación y cómo hacer calculos de los impuestos.',
              nine:
                'Le brindamos cómo importar y exportar servicios en Brasil.',
            },
          },
          four: {
            title: 'Entreinamientos personalizados',
            description:
              'Ofrecemos las siguientes opciones en entrenamiento in-company o on-line:',
            items: {
              one: 'Aplicación de los Incoterms.',
              two: 'Paso a paso en operaciones de importación.',
              three: 'Paso a paso en operaciones de exportación.',
              four:
                'Tributación brasileña sobre operaciones de comércio internacional.',
              five: 'Como exportar e importar servicios en Brasil.',
              six: 'Creando una cultura de exportación en su empresa.',
              seven:
                'Operaciones especiales, drawback y exportación y importación temporarias.',
              eight: 'Paso a paso para participar en una feria internacional.',
              nine: 'Servicio al cliente - desarrollo de equipo ',
            },
          },
          five: {
            title: 'Agentes internacionales',
            description:
              'A través de una amplia red de representantes en el exterior, nosotros ofrecemos los siguientes servicios:',
            items: {
              one: 'Servicio expreso de carga.',
              two: 'Retiro exclusivo o consolidado de mercadería.',
              three: 'Servicio de carga, descarga y maquila.',
              four: 'Almacenaje de cargas refrigeradas y/o congeladas.',
              five: 'Maquilas (customizado,  ensemblaje, cambio de embalaje)',
              six:
                'Administración y control de inventarios con fotos y informativos.',
              seven:
                'Analisis fiscal y de derechos de impuestos fuera de Brasil de responsabilidad por parte de empresas brasileñas. ',
            },
          },
        },
      },
      common: {
        provideyou: 'Proveemos:',
      },
      solutions: {
        title: 'Conozca nuestras soluciones en el exterior.',
        description:
          'Crea un diferencial para su negocio a través de soluciones en almacenaje, ensamblaje, cambio de embalaje, seguimiento de carga y gestón de inventario.',
      },
      contact: {
        title: 'Trabaje para incrementar su negocio ahora mismo.',
        buttonText: 'Hable con nosotros',
      },
      footer: {
        aboutus: '¿Quiénes somos?',
        services: 'Servicios',
        solutions: 'Soluciones',
        contactus: 'Contacto',
      },
    },
  },
};
