import React from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';

import GridContainer from '../../helpers/GridContainer';

import logoLightImg from '../../assets/images/logo-light.svg';

import { Container, MobileContainer, MenuIcon, ButtonSistem } from './styles';

type THeader = {
  variant?: 'light';
};

const Header: React.FC<THeader> = ({ variant = 'light' }: THeader) => {
  const { t } = useTranslation();

  return (
    <Container variant={variant}>
      <GridContainer>
        <div className="content">
          <Link to="/">
            {variant === 'light' && (
              <img src={logoLightImg} alt="Via lactea transportes" />
            )}
          </Link>
          <nav>
            <a href="/home">Inicio</a>
            <a href="/about">{t('header.aboutus')}</a>
            <a href="/servicos">{t('header.services')}</a>
            <a href="/contact">{t('header.contactus')}</a>
          </nav>
          <a href='https://www.sistema.lactlog.com.br/LoginSite' target='_blank'>
            <ButtonSistem>Rastreamento</ButtonSistem>
          </a>
        </div>
        <MobileContainer>
          {variant === 'light' && (
            <img
              src={logoLightImg}
              className="logo"
              alt="Via láctea transportes"
            />
          )}
          <IconButton>
            <MenuIcon variant={variant}>
              <div className="line" />
              <div className="line" />
              <div className="line" />
            </MenuIcon>
          </IconButton>
        </MobileContainer>
      </GridContainer>
    </Container>
  );
};

Header.defaultProps = {
  variant: 'light',
};

export default Header;
